import React, { Component, Fragment } from 'react';
import Navbar from '../../UI/Navbar';
import graph from '../../../assets/DistributionsofCasesAmongstDefenseAttorneys.PNG'

import './DAOffice.css';

class Stats extends Component {
    render () {
        return (
            <Fragment>
                    <Navbar></Navbar>
                    <div className="container">
                        <div className="content-wrapper-no-flex font-display text-base " style={{backgroundColor: 'rgba(112,112,112,0.05)'}}>
                            <div className="
                                    align-self-center
                                    font-bold text-xl font-display 
                                    border-b-2 border-mustard w-max">
                                   District Attorney Statistics
                                </div>
                                {/* Cases Referred */}
                                <iframe title="Cases Referred" className="w-full h-screen mt-4" src="https://app.powerbigov.us/view?r=eyJrIjoiZDg0ZTA4OWUtNjA3YS00ZTY1LWE5ODYtNDM3YTFjNmVhYTkwIiwidCI6IjBjNjc2MzA5LTZkYTgtNGQ0ZS05MDI5LWIzMTk4Yjk2YzYzNiJ9" frameborder="0" allowFullScreen="true"></iframe>
                                {/* Decisions after case review*/}
                                <iframe title="Decisions after case review" className="w-full h-screen mt-4" src="https://app.powerbigov.us/view?r=eyJrIjoiNmQ0MDQxNzEtMzJkOC00MjA3LWJiM2ItYmJmODMwZjQ1NjZlIiwidCI6IjBjNjc2MzA5LTZkYTgtNGQ0ZS05MDI5LWIzMTk4Yjk2YzYzNiJ9" frameborder="0" allowFullScreen="true"></iframe>
                                {/* How cases are distributed amongst defense attorneys */}
                                
                                <div className="w-full h-screen mt-4" >
                                    <img src={graph}
                                    alt="Graph that shows how cases are distributed amongst defense attorneys">
                                    </img>
                                </div>
                                {/* Person Demographics */}
                                <iframe title="Person Demographics" className="w-full h-screen mt-4" src="https://app.powerbigov.us/view?r=eyJrIjoiMGFiNGM2YTktYmJlOS00NTcyLTgzY2QtMGYxOTEwZDYwMzQ0IiwidCI6IjBjNjc2MzA5LTZkYTgtNGQ0ZS05MDI5LWIzMTk4Yjk2YzYzNiJ9" frameborder="0" allowFullScreen="true"></iframe>
                                {/* How quickly cases move through the system */}
                                <iframe title="How quickly cases move through the system" className="w-full h-screen mt-4" src="https://app.powerbigov.us/view?r=eyJrIjoiNGIyYWVjZjctNGRmMi00ZmUxLWJmMzQtM2E4NTBlNTFlMjNiIiwidCI6IjBjNjc2MzA5LTZkYTgtNGQ0ZS05MDI5LWIzMTk4Yjk2YzYzNiJ9" frameborder="0" allowFullScreen="true"></iframe>
                                {/* How cases end */}
                                <iframe title="How cases end" className="w-full h-screen mt-4" src="https://app.powerbigov.us/view?r=eyJrIjoiNzE1ODliMGUtOTcwOC00ZmIzLTg4NGQtNTcxYjgwOTU3MGE1IiwidCI6IjBjNjc2MzA5LTZkYTgtNGQ0ZS05MDI5LWIzMTk4Yjk2YzYzNiJ9" frameborder="0" allowFullScreen="true"></iframe>
                                {/* Cases that end with a sentence */}
                                <iframe title="Cases that end with a sentence" className="w-full h-screen mt-4" src="https://app.powerbigov.us/view?r=eyJrIjoiNWRmMGM1YTItZDYzOC00Mjk0LWI3NWEtM2Y2MjE4NzFhNjMwIiwidCI6IjBjNjc2MzA5LTZkYTgtNGQ0ZS05MDI5LWIzMTk4Yjk2YzYzNiJ9" frameborder="0" allowFullScreen="true"></iframe>

                            <p className="mt-4">Note: Statistics are for time frame of 01/01/2019 - 12/31/2020.</p>​
                        </div>
                    </div>
            </Fragment>
        )
    }
}

export default Stats;