const data = [ 
  // {link:"https://www.independent.com/2022/07/15/santa-barbara-district-attorney-joyce-dudley-honored-with-2022-champion-for-kids-award-at-the-annual-national/" , title:"Santa Barbara District Attorney Joyce Dudley Honored With 2022 Champion for Kids Award"},
  {link:"https://syvnews.com/news/local/crime-and-courts/no-arrests-made-in-santa-barbara-county-probation-district-attorneys-office-wellness-check/article_06e9203c-a3aa-50d7-9241-762a21337ed0.html" , title:"No arrests made in Santa Barbara County Probation, District Attorney's Office wellness check"},
  // {link:"https://newspress.com/dudley-to-retire-as-district-attorney/" , title:"Joyce Dudley to retire as District Attorney"},
  {link:"http://www.santamariasun.com/news/21085/more-than-a-dozen-arrested-during-human-trafficking-sting/" , title:"More than a dozen arrested during human trafficking sting"},
  // {link:"https://www.pacbiztimes.com/2021/09/24/our-view-santa-barbara-da-takes-a-stand-on-gun-violence/" , title:"Our View: Santa Barbara DA takes a stand on gun violence"},
  {link:"https://newspress.com/eight-killed-in-indianapolis-mass-shooting/" , title:"Eight killed in Indianapolis mass shooting - Santa Barbara News-Press"},
          {link:"https://www.ksby.com/news/local-news/ca-leaders-raise-concerns-over-proposed-bill-that-would-reduce-jail-time-for-some-robberies" , title:"CA leaders raise concerns over proposed bill that would reduce jail time for some robberies"},
          {link:"https://newspress.com/traffic-crime-and-fire-blotter-117/" , title:"Santa Barbara News-Press - Ricardo Sanchez, 28, sentenced"},
          {link:"https://keyt.com/news/safety/2021/02/04/tipline-protect-yourself-and-loved-ones-from-romance-scams-and-money-mules/" , title:"Tipline Investigation: Protect yourself and loved ones from Romance Scams and Money Mules"},
          {link:"https://www.noozhawk.com/article/joyce_dudley_with_grief_are_joy_and_happiness_possible_again?omhide=true&utm_source=Noozhawk&utm_campaign=d220460026-EMAIL_CAMPAIGN_2019_03_18_03_36_COPY_01&utm_medium=email&utm_term=0_9ec8acd2c4-d220460026-247208801" , title:"Joyce Dudley: Can Grief Allow Us to Be Joyful and Happy Again?"},
          {link:"https://www.noozhawk.com/article/joyce_dudley_vicki_johnson_protecting_vulnerable_from_scammers_20210124" , title:"Joyce Dudley & Vicki Johnson: Protecting the Vulnerable from Pandemic Scammers"},
          {link:"https://www.youtube.com/watch?feature=youtu.be&v=EBe3QdpBJ-Y&app=desktop" , title:"60 Second At Ease Video"},
          {link:"https://santamariatimes.com/news/local/crime-and-courts/santa-barbara-county-da-joyce-dudley-reappointed-chairwoman-of-post/article_d875b48a-5593-5947-bb9d-3034589b038e.html" , title:"Santa Barbara County DA Joyce Dudley reappointed chairwoman of POST"},
          {link:"https://keyt.com/news/top-stories/2020/11/15/santa-barbara-county-da-recounts-warmth-and-respect-from-kamala-harris-2/" , title:"Santa Barbara County DA recounts warmth and respect from Kamala Harris"},
          {link:"https://keyt.com/news/top-stories/2020/10/29/cada-expands-summit-for-danny-community-hike/"  , title:"CADA expands Summit for Danny Community Hike | NewsChannel 3-12"},
          {link:"https://countyofsb.org/da/msm_county/documents/DiversionProgramProject1.pdf" , title:"Santa Barbara County District Attorney Diversion Program"},
          {link:"https://countyofsb.org/da/msm_county/documents/LawyersMagazineArticleJoyceDiversion.pdf" , title:"Lawyers Magazine Article Joyce E. Dudley Diversion"},
          {link:"https://www.noozhawk.com/article/Foodbank-seeking-community-volunteers-in-Santa-Maria-Lompoc?utm_source=Noozhawk&utm_campaign=515522dae3-EMAIL_CAMPAIGN_2018_08_27_10_56_COPY_01&utm_medium=email&utm_term=0_9ec8acd2c4-515522dae3-247208801" , title:"Foodbank Seeks Community Volunteers in Santa Maria, Lompoc | Good for Santa Barbara - Noozhawk.com"},
          {link:"https://www.newsmakerswithjr.com/post/at-sentencing-of-golden-state-killer-sb-da-joyce-dudley-spoke-to-victims-of-healing-and-love" , title:"Newsmakers with Jr: At Sentencing of Golden State Killer, SB DA Joyce Dudley Spoke to Victims of Healing and Love"},
          {link:"https://keyt.com/news/santa-barbara-s-county/2020/06/29/district-attorneys-and-neighbors-speak-out-after-so-called-golden-state-killer-enters-guilty-pleas/" , title:"Keyt: District Attorneys and neighbors speak out after &quot;Golden State Killer&quot; enters guilty pleas"},
          {link:"https://newspress.com/golden-state-killer-pleads-guilty/" , title:"Santa Barbara News Press: Golden State Killer pleads guilty"},
          {link:"https://www.wfmz.com/news/cnn/us-national/ca-golden-state-killer-da-beginning-of-healing/video_6b15d808-f1d9-5950-8988-7f0c235a7d53.html" , title:"CA: GOLDEN STATE KILLER/ DA: BEGINNING OF HEALING | CNN | wfmz.com"},
          {link:"https://www.noozhawk.com/article/joyce_dudley_the_pain_of_looking_away" , title:"Joyce Dudley: The Pain of Looking Away"},
          {link:"https://www.noozhawk.com/article/joyce_dudley_vickie_johnson_may_is_elder_abuse_awareness_month" , title:"Joyce Dudley & Vickie Johnson: May Is Elder Abuse Awareness Month | Opinions - Noozhawk.com"},
          {link:"https://keyt.com/lifestyle/community/2020/05/13/look-for-the-helpers-das-office-pitches-in-learning-materials-donated-farmworkers-kids/" , title:"Look for the Helpers: DA's office pitches in, learning materials donated to farmworkers kids "},         
          {link:"https://www.noozhawk.com/article/prosecutors_against_gun_violence_summit_20200507?utm_source=Noozhawk&utm_campaign=efe42edbb5-EMAIL_CAMPAIGN_2019_03_18_03_36_COPY_01&utm_medium=email&utm_term=0_9ec8acd2c4-efe42edbb5-247208801" , title:"Prosecutors Against Gun Violence Summit Highlights In-Home Crimes on Rise Amid Coronavirus Pandemic | Local News - Noozhawk.com"},
          {link:"https://keyt.com/news/safety/2020/04/25/domestic-violence-during-covid-shelter-at-home-uptick-find-out-how-you-can-help/" , title:"Uptick in domestic violence during COVID-19 shelter at home; find out how you can help-Keyt"},
          {link:"https://keyt.com/news/santa-maria-north-county/2020/04/22/pets-of-slain-lompoc-woman-delivered-to-her-surviving-family-in-texas/" , title:"Pets of slain Lompoc woman delivered to her surviving family in Texas- KEYT"},
          {link:"https://www.santaynezvalleystar.com/domestic-violence-victims-and-their-pets-need-a-safe-haven/" , title:"Domestic violence victims and their pets need a safe haven - Santa Ynez Valley Star"},
          {link:"http://www.santamariasun.com/news/19543/coronavirusrelated-scams-pop-up-across-santa-barbara-county/" , title:"Coronavirus-related scams pop up across Santa Barbara County | News | Santa Maria Sun, CA"},
          {link:"https://www.independent.com/2020/04/07/santa-barbara-officials-call-out-domestic-abuse/" , title:"Santa Barbara Officials Call Out Domestic Abuse - The Santa Barbara Independent"},
          {link:"https://newspress.com/as-scammers-try-to-make-money-from-coronavirus-pandemic-u-s-law-enforcement-gives-tips-on-how-to-spot-and-stop-them/" , title:"Santa Barbara News-Press:  As scammers try to make money from coronavirus pandemic, U.S. law enforcement gives tips on how to spot and stop them"},
          {link:"https://www.independent.com/2020/03/23/u-s-attorneys-office-continues-its-critical-public-safety-functions-and-urges-public-to-report-suspected-covid-19-fraud/" , title:"SB Independent: How to Report Suspected Scams related to COVID - 19"},
          {link:"https://www.independent.com/2020/03/03/santa-barbara-da-reflects-on-weinstein-verdict/" , title:"Santa Barbara DA Reflects on Weinstein Verdict - The Santa Barbara Independent"},
          {link:"https://countyofsb.org/da/msm_county/documents/IVSafeReport.pdf" , title:"IV Safe Report to the People"},
          {link:"https://countyofsb.org/da/msm_county/documents/ReporttothePeopleFinalVersion.pdf" , title:"Report to the People 2010-2014"},
          {link:"https://post.ca.gov/Use-of-Force-Standards#UseOfForce" , title:"AB 392: Use of Force Standards, General Public Video "},
{link:"https://www.noozhawk.com/article/california_highway_patrol_santa_ynez_valley_highways_20191006" , title:"CHP Increases Traffic Enforcement after Crashes on Santa Ynez Valley Highways | Local News - Noozhawk.com"},
          {link:"https://www.8newsnow.com/news/local-news/prosecutors-gather-in-las-vegas-for-gun-violence-summit-mass-shootings-in-america-took-center-stage/" , title:"Prosecutors gather in Las Vegas for gun violence summit; mass shootings in America took center stage "},
          {link:"https://www.keyt.com/news/fire/victim-help-from-district-attorneys-office-underway-after-conception-tragedy/1121802174" , title:"KEYT-Victim help from District Attorney's Office underway after Conception tragedy "},
          {link:"https://www.noozhawk.com/article/santa_maria_elks_event_shows_appreciation_for_law_enforcement_officers?utm_source=Noozhawk&utm_campaign=3278f9834d-EMAIL_CAMPAIGN_2019_03_18_03_36_COPY_01&utm_medium=email&utm_term=0_9ec8acd2c4-3278f9834d-247208801" , title:"Santa Maria Elks Show Appreciation for Law Enforcement Officers | Local News - Noozhawk.com"},
          {link:"https://www.keyt.com/news/santa-barbara-s-county/district-attorney-warns-people-to-think-twice-about-leaving-children-or-pets-in-cars/1112055494" , title:"Keyt: District Attorney warns people to think twice about leaving children or pets in cars-Heatwave warning "},
          {link:"https://www.keyt.com/news/santa-barbara-s-county/santa-barbara-county-district-attorney-files-legal-action-against-wildfire-protection-company/1105926766" , title:"KEYT: Santa Barbara County DA files legal action against wildfire protection company "},
          {link:"https://www.keyt.com/news/district-attorney-s-victim-witness-assistance-program-offers-support-in-light-of-friday-s-violence/1089344526" , title:"KEYT: District attorney's Victim Witness Assistance Program offers support in light of Friday's violence"},
          {link:"https://www.independent.com/2019/06/22/larger-rescue-mission-expands-life-changing-purpose/" , title:"SB Independent: Larger Rescue Mission Expands Life-Changing Purpose"},
          {link:"https://santamariatimes.com/news/local/casa-of-hope-safe-home-for-sex-trafficking-victims-to/article_f9920d01-dbab-5d8a-870a-3358d0257641.html?utm_medium=social&utm_source=email&utm_campaign=user-share" , title:"SM Times:Casa of Hope: Safe home for sex trafficking victims to welcome its first residents"},
          {link:"https://www.keyt.com/lifestyle/community/santa-barbara-rescue-mission-completes-six-year-remodeling-project/1088024261" , title:"KEYT:Santa Barbara Rescue Mission completes six-year remodeling project"},
          {link:"https://ksby.com/news/local-news/2019/06/13/supervisors-allocate-money-for-improvements-at-district-attorneys-offices-in-santa-maria" , title:"KSBY: Supervisors allocate money for improvements at district attorney&rsquo;s offices in Santa Maria"},
          {link:"https://www.google.com/url?rct=j&sa=t&url=https://www.keyt.com/news/santa-barbara-s-county/memorial-day-lunch-comes-with-a-legislative-update/1081566224&ct=ga&cd=CAEYACoUMTc0ODA1Mjc0MTAyNzIyMjg5MjMyGjEwNzYxZjQyYzg5N2RhM2I6Y29tOmVuOlVT&usg=AFQjCNHp_AyYzfdZy4jggI9ilFwsOqk0Xg" , title:"KEYT:Memorial Day lunch comes with a legislative update, meal and tearful honor "},
          {link:"https://www.noozhawk.com/article/conference_focus_on_toll_repeated_trauma_takes_on_first_responders_20190512" , title:"Noozhawk: Conference Puts Focus on Helping First Responders Deal with Toll from Repeated Trauma"},
          {link:"https://www.keyt.com/news/santa-maria-north-county/santa-barbara-county-agencies-discuss-ongoing-efforts-against-human-trafficking/1005540796" , title:"Santa Barbara County agencies discuss ongoing efforts against human trafficking - KEYT"},
          {link:"https://santamariatimes.com/news/local/district-attorney-s-office-donates-books-to-santa-maria-library/article_b9a518f2-c7cb-50e8-a695-516ce64df67b.html" , title:"Santa Maria Times: District Attorney's Office turns to books to prevent crime"},
          {link:"https://www.noozhawk.com/article/book_donations_aimed_at_boosting_literacy_cutting_truancy?utm_source=Noozhawk%27s+A.M.+Report&utm_campaign=4603d5de2b-EMAIL_CAMPAIGN_2018_01_11_COPY_01&utm_medium=email&utm_term=0_cb376aba6e-4603d5de2b-246066713" , title:"Noozhawk: Santa Maria Public Library shop gets used children's books from Santa Barbara County District Attorney's Office "},
          {link:"http://www.foxla.com/news/355975918-video" , title:"Growing Danger of Fetanyl: Doctor and DA discuss - Video | KTTV"},
          {link:"https://www.noozhawk.com/article/golden_state_killer_suspect_face_consolidated_trial_sacramento_13_murders" , title:"Noozhawk: Accused Golden State Killer Will Be Tried In Sacramento"},
          {link:"https://www.noozhawk.com/article/joyce_dudley_mental_health_services_early_childhood_access_20180816" , title:"Noozhawk: Joyce Dudley: Access to Mental Health Services Critical During Early Childhood"},
          {link:"https://www.noozhawk.com/article/santa_barbara_district_attorney_warns_of_blackmail_scam" , title:"Noozhawk: Santa Barbara District Attorney Warns of Blackmail Scam"},
          {link:"https://www.noozhawk.com/article/pedal_the_pacific_bike_ride_sex_trafficking_survivor_facility_santa_barbara?utm_source=Noozhawk%27s+A.M.+Report&utm_campaign=516f3b64b0-EMAIL_CAMPAIGN_2018_01_11_COPY_01&utm_medium=email&utm_term=0_cb376aba6e-516f3b64b0-246066713" , title:"Noozhawk: Pedal the Pacific Cyclists On a Roll as They Raise Awareness About Sex Trafficking"},
          {link:"http://sacramento.cbslocal.com/video/3861754-raw-santa-barbara-da-announces-4-new-criminal-charges-against-suspected-golden-state-killer/" , title:"Raw: Santa Barbara DA Announces 4 New Criminal "},
          {link:"https://countyofsb.org/da/msm_county/Video/GoldenStateKillerPKG510.mp4" , title:"Eyewitness News ABC7: Golden State charged with 4 more counts of murder"},
          {link:"http://www.keyt.com/news/crime/santa-barbara-county-da-files-four-counts-of-first-degree-murder-against-golden-state-killer-/740565873" , title:"Keyt: Santa Barbara County DA files four counts of first-degree murder against 'Golden State Killer'"},
          {link:"http://m.independent.com/news/2018/apr/21/human-trafficking-its-happening-front-you/" , title:"Santa Barbara Independent| Human Trafficking: It's Happening in Front of You"},
          {link:"http://www.keyt.com/lifestyle/what-s-right/special-olympics-polar-plunge-at-leadbetter-beach/732391941" , title:"KEYT: Polar Plunge"},
          {link:"http://www.countyofsb.org/asset.c/4095" , title:"FRAUD ALERT: &quot;Land Technicians&quot; Attempting to Gain Unauthorized Access to Homes"},
          {link:"https://www.noozhawk.com/article/junior_league_to_honor_dudley_at_10th_annual_gala?utm_source=Noozhawk%27s+P.M.+Report&utm_campaign=714aa8f68b-EMAIL_CAMPAIGN_2018_01_22&utm_medium=email&utm_term=0_096bcce0ff-714aa8f68b-247133893" , title:"Noozhawk: Junior League to Honor Dudley at 10th Annual Gala"},
          {link:"https://www.youtube.com/watch?v=Tm0vBIOc0pg" , title:"Animal Shelter steps up to care for Domestic  Violence Victim&rsquo;s pets: Santa Maria Humane Society Misha&rsquo;s Story"},
          {link:"https://www.independent.com/news/2018/feb/15/changes-are-coming-california-sex-offender-registr/" , title:"SB Independent: Changes Are Coming to Califoria Sex Offender Registry. A New Approach Will Consider the Severity of Crimes."},
          {link:"https://www.google.com/url?rct=j&sa=t&url=http://www.keyt.com/news/safety/protecting-fire-and-mudslide-survivors-from-scammers/698767291&ct=ga&cd=CAEYACoUMTQ1MjA1MzEwOTk1MTcxOTQxNTYyGjEwNzYxZjQyYzg5N2RhM2I6Y29tOmVuOlVT&usg=AFQjCNHosNowyzos3SSbSxyPCz_8Rz3caA" , title:"KEYT: Protecting Fire and Mudslide Survivors from Scammers"},
          {link:"http://www.keyt.com/news/sbcc-student-claims-78-uber-ride-was-price-gouging/689662945" , title:"KEYT: SBCC student claims $78 Uber ride was price gouging "},
          {link:"http://dailynexus.com/2018-01-15/santa-barbara-community-mourns-lives-lost-in-montecito-mudslides/" , title:"The Daily Nexus: Santa Barbara Community Mourns Lives Lost in Montecito Mudslides"},
          {link:"https://www.nytimes.com/2018/01/15/well/live/understanding-grief.html" , title:"The New York Times: Underatanding Grief"},
          {link:"http://www.santamariasun.com/news/16972/santa-barbara-county-law-enforcement-officials-say-their-methods-for-battling-sex-and-human-trafficking-are-improving/", title:"SM Sun: Santa Barbara County law enforcement officials say their methods for battling sex and human trafficking are improving "},
          {link:"http://www.keyt.com/news/crime/district-attorney-warns-against-scams-after-the-thomas-fire/678390113" , title:"KEYT: District Attorney warns against scams after the Thomas Fire"},
          {link:"https://tedxsantabarbara.com/2017/megan-rheinschild/" , title:"TEDx: The Face of Human Trafficking/Megan Rheinschild"},
{link:"https://www.cnet.com/news/robocalls-mobile-phones-how-to-stop/" , title:"Cnet: How to Stop Robocalls"},
          {link:"https://www.post.ca.gov/did-you-know-video-series.aspx" , title:"POST: Did You Know? Video Series"},
          {link:"http://www.santamariasun.com/cover/16616/no-longer-silent-local-support-organizations-law-enforcement-and-survivors-speak-out-to-end-the-cycle-of-domestic-violence/" , title:"Santa Maria Sun: No longer silent: Local support organizations, law enforcement, and survivors speak out to end the cycle of domestic violence "},
          {link:"https://www.youtube.com/watch?v=WBlvzB0Gmkw&t=10s" , title:"Sexual Assault Awareness Video"},
          {link:"http://santamariatimes.com/news/local/da-dogs-program-in-santa-maria-first-of-its-kind/article_57be42a6-09e6-59bd-a2e4-06fd6df016ae.html#2" , title:"SM Times: DA Dogs program in Santa Maria first of its kind in state"},
          {link:"http://m.independent.com/news/2017/sep/08/da-seeks-death-penalty-triple-han-homicide/" , title:"SB Independant: DA Seeks Death Penalty in Triple Han Homicide "},
{link:"https://www.noozhawk.com/parent_nooz/article/ben_romo_joyce_dudley_crime-fighting_programs_start_at_beginning_20170904" , title:"Noozhawk: Ben Romo: For DA Joyce Dudley, the Most Successful Crime-Fighting Programs Start at the Beginning"},
          {link:"https://www.google.com/url?rct=j&sa=t&url=https://www.noozhawk.com/article/absentee_rate_shows_small_decline_santa_barbara_unified_school_district&ct=ga&cd=CAEYASoUMTc4NjI5NTMwMjQ1OTMxMDE0MzIyGjExNTU5OWFkODVkODMyZTQ6Y29tOmVuOlVT&usg=AFQjCNG1-lXU7N3PX3gruh38361wiFMxlg" , title:"Noozhawk: Absentee Rate Shows Small Decline in Santa Barbara Unified School District"},
          {link:"https://www.noozhawk.com/article/santa_maria_humane_society_dogs_district_attorneys_office_20170729" , title:"Noozhawk: Santa Maria Humane Society, District Attorney&rsquo;s Office Join Forces to Give Dogs a Day"},
          {link:"http://www.independent.com/news/2017/jul/31/district-attorneys-office-receives-loving-makeover/" , title:"Independent: District Attorney&rsquo;s Office Receives a Loving Makeover"},
          {link:"http://www.keyt.com/lifestyle/animals/new-program-pairs-shelter-dogs-with-workout-buddies-at-district-attorneys-office/595055502" , title:"Keyt: New program pairs shelter dogs with workout buddies at District Attorney's office"},
          {link:"https://countyofsb.org/da/msm_county/documents/wheretoreportscams.pdf" , title:"Where to Report Scams"},
          {link:"https://countyofsb.org/da/msm_county/Video/pj_high.mp4" , title:"Procedural Justice"},
          {link:"http://www.keyt.com/news/safety/senior-scam-presentations-aim-to-increase-awareness-of-fraud/538080116" , title:"Keyt: Senior Scam presentations aim to increase awareness of fraud"},
          {link:"http://santamariatimes.com/news/local/video-victims-rights-week/youtube_84be5ece-009e-51f7-99e0-4981a7db3ea6.html" , title:"Santa Maria Times: National Victim's  Rights Week 2017"},
          {link:"https://www.noozhawk.com/article/former_ucsb_star_tori_tsolis_sets_womens_elite_record_in_state_street_mile?utm_source=Noozhawk%27s+A.M.+Report&utm_campaign=21a76870a6-EMAIL_CAMPAIGN_2017_06_05&utm_medium=email&utm_term=0_cb376aba6e-21a76870a6-246066713" , title:"Noozhawk: Former UCSB Star Tori Tsolis Sets Women&rsquo;s Elite Record in State Street Mile "},
          {link:"https://www.noozhawk.com/article/mother_of_murdered_man_receives_courage_award?utm_source=Noozhawk%27s+A.M.+Report&utm_campaign=44fdbb62a8-EMAIL_CAMPAIGN_2017_04_20&utm_medium=email&utm_term=0_cb376aba6e-44fdbb62a8-246066713" , title:"Celebration for National Crime Victims&rsquo; Week 2017 Honoring our local Heroes "},
          {link:"https://countyofsb.org/da/msm_county/documents/EEcclesFINALACFP2017Poster.pdf" , title:"Dogs in the Courthouse: Challenges and Benefits in the Use of Facility Dogs"},
          {link:"http://www.keyt.com/news/santa-barbara-s-county/elderly-homeless-woman-reunited-with-children-after-a-decade-on-the-streets/375752972" , title:"Responding to the needs of our Homeless"},
          {link:"http://www.keyt.com/news/santa-barbara-s-county/santa-barbara-county-agencies-receive-federal-grant-to-combat-human-trafficking/105912883", title:"Santa Barbara County Agencies Receive Federal Grant To Combat Human Trafficking - KEYT"},
          {link:"https://countyofsb.org/da/msm_county/documents/PublicAlertFireInspectionScam_pr.pdf" , title:"Public Alert: Fire Inspection Scam"},
          {link:"https://countyofsb.org/da/msm_county/documents/IVSafeReport.pdf" , title:"IV Safe Report to the People"},
          {link:"http://www.keyt.com/news/community-groups-take-the-polar-plunge-for-special-olympics-athletes/38242156" , title:"Keyt: Santa Barbara District Attorney's Office Top  Team Fundraiser for Special Olympics"},
          {link:"https://countyofsb.org/da/msm_county/documents/CasaSBWomenLawyersDinner.pdf" , title:"Attorney of the Year"},
          {link:"http://www.noozhawk.com/article/da_using_case_severely_burned_teen_cautionary_tale" , title:"Noozhawk: DA Using Case of Severely Burned Teen as Cautionary Tale"},
          {link:"https://countyofsb.org/da/msm_county/documents/NA_DCST_FINAL.pdf" , title:"Needs Assessment of Domestic Child Sex Trafficking in Santa Barbara County June 2015"},
          {link:"https://countyofsb.org/da/msm_county/documents/NA_DCST_Appendicies_FINAL.pdf" , title:"Appendix A: Sex Trafficking in California"},
          {link:"https://lp.post.ca.gov/post/resources/adds/jPlayerAdds/dykseries.htm" , title:"Preventing school violence"},
          {link:"http://www.noozhawk.com/article/ymca_reaching_for_stars" , title:"Noozhawk: Youth & Family Services YMCA Fundraiser Aims to Keep Young People &lsquo;Reaching for Stars&rsquo;"},
          {link:"http://m.lompocrecord.com/news/local/grand-jury-class-program-keeps-county-truancy-down/article_9b9cdf0b-322f-5557-b909-6ad21c66b7c3.html?mobile_touch=true" , title:"Lompoc Record: Grand jury: CLASS program keeps county truancy down"},
          {link:"http://www.noozhawk.com/article/santa_barbara_truancy_programs_helps_keep_children_in_school_grand_jury" , title:"Noozhawk: Santa Barbara DA&rsquo;s Truancy Program Helps Keep Children in School, Grand Jury Report Finds"},
          {link:"http://www.kaltura.com/tiny/59eqp" , title:"Santa Barbara County District Attorney's Truancy Program"},
          {link:"http://www.keyt.com/news/mission-for-mentors-joyce-rosies-story/28168720" , title:"Prevent Crime-become a Mentor"},
          
          
          {link:"https://countyofsb.org/da/msm_county/documents/AnimalAbusePresentationFlyer.pdf" , title:"Sept 4, 2014 Animal Abuse Presentation"},
         
          {link:"http://www.keyt.com/news/community-leaders-come-together-to-end-elder-abuse/24671312" , title:"KEYT:   Community Leaders Come Together To End Elder Abuse &quot;Stand Up Against Elder   Abuse&quot; Committed to Raise Awareness "},
          {link:"http://www.youtube.com/watch?v=pSlmz0hjFwY" , title:"DA Dudley forms Anti-trafficking Task Force"},
          {link:"https://countyofsb.org/da/msm_county/documents/OrcuttPioneerColumnFebruary2014.pdf" , title:"Orcutt Pioneeer Column February 2014 "},
          {link:"http://www.noozhawk.com/article/crash_victim_offers_thanks_to_many_in_santa_barbara_20140210" , title:"Noozhawk: Letter to the Editor: Crash Victim Offers Thanks to Many in Santa Barbara"},
          {link: "https://countyofsb.org/da/msm_county/documents/ELDERABUSEPLEDGE.pdf", title:"2/25/2014 Stand up Against Elder Abuse at SBPD"},
          {link:"http://www.kaltura.com/tiny/5yvvz" , title:"KEYT: SB County District Attorney Forms Anti-Animal Abuse Task Force "},
          {link:"http://www.santamariasun.com/commentary/10475/the-santa-barbara-county-district-attorneys-office-says-its-truancy-program-is-working/" , title:"SM Sun: Santa Barbara District Attorneys Office says its Truancy Program is working"},
          {link:"http://www.sfgate.com/crime/article/Limit-on-state-anticrime-legislation-eased-a-bit-4927392.php" , title:"SF Gate: Limit on state anticrime legislation eased - a bit "},
          {link:"http://www.independent.com/news/2013/oct/20/santa-barbabra-bigwigs-talk-gun-control/" , title:"SB Independent: Town hall meeting on Gun Violence"},
          {link:"http://sdrv.ms/19zRl8C" , title:"Truancy: CLASS Presentation to Superintendents"},
          {link:"http://www.independent.com/news/2013/oct/03/sb-kids-not-too-cool-school/" , title:"SB Independent: S.B. Kids Not Too Cool For School "},
          {link:"http://www.kcoy.com/story/23572121/state-ag-says-california-facing-truancy-crisis" , title:"KCOY State AG Says California Facing Elementary Truancy Crisis"},
          {link:"https://www.youtube.com/watch?v=qFZKF7N9W7g&feature" , title:"NUSA National Neighborhood of the Year Competition: Milpas Community Association &quot;2nd Place - National&quot;"},
          {link:"https://countyofsb.org/da/msm_county/documents/AB65sbnewspress.pdf" , title:"KCOY News Local News: Governor Signs SLO Assembly Member's Bill To Protect Rape Victims "},
{link:"https://countyofsb.org/da/msm_county/documents/sbnptorchrun2013.pdf" , title:"Santa Barbara New Press: Special Olympics Torch Run for Southern California"},
{link:"http://www.noozhawk.com/article/060513_santa_barbara_law_enforcement_torch_run/" , title:"Noozhawk: Santa Barbara Law Enforcement Torch Run "},
          
          {link:"http://santamariatimes.com/news/local/crime-and-courts/da-will-not-pursue-death-penalty-in-ibarra-murder/article_c913f47e-c439-11e2-a769-001a4bcf887a.html" , title:"Santa Maria Times: DA will not pursue death penalty in Ibarra murder 14-year-old suspect, 11th in the case, arrested by police Wednesday "},
         
          {link:"http://www.santamariasun.com/news/9671/focusing-on-the-underserved/" , title:"Santa Maria Sun: Focusing on the underserved. The District Attorney's Office reaches out to indigenous populations in Santa Barbara County "},
{link:"http://www.noozhawk.com/article/040413_community_action_commission_honors_three_champions/" , title:"Noozhawk: Community Action Commission Honors Three 'Champions'"},
         
          {link:"http://www.independent.com/news/2013/apr/03/sexual-assault-response/" , title:"The Santa Barbara Independent: Sexual Assault Response"},
          {link:"https://countyofsb.org/da/msm_county/Video/SBCADALegacy.mp4" , title:"Executive  Director of Counsel on Alcoholism and Drug and Drug Abuse retires"},
          {link:"http://www.google.com/url?sa=X&q=http://www.santamariasun.com/news/9516/rapebyfraud-bill-is-one-step-closer-to-being-law/&ct=ga&cad=CAcQAhgAIAAoATAAOABA9OOtigVIAVgBYgVlbi1VUw&cd=KU4xFFtTeBc&usg=AFQjCNG1GDp6wDXNfShfQCddgMVISaL9Sw" , title:"Rape-by-fraud bill is one step closer to being law"},
{link:"https://countyofsb.org/da/msm_county/Video/AssemblyBill65Rape.mp4" , title:"Assembly Bill 65 Rape"},
          {link:"https://countyofsb.org/da/msm_county/Video/CBS13.mp4" , title:"CBS 13 Sacramento: Attorney Seeking To Overturn California Rape Law that doesn't Protect All Victims"},
          {link:"http://m.kcoy.com/r.aspx?url=http%3a%2f%2fkion.videodownload.worldnow.com%2fKION_20130129013643480AC.mp4&txt=Santa%20Barbara%20County%20DA%20Joyce%20Dudley%20Talks%20About%20Covarrubias%20Investigation" , title:"KCOY Santa Barbara County DA Joyce Dudley Talks About Covarrubias Investigation"},
{link:"http://www.noozhawk.com/article/010813_officials_change_outdated_rape_by_fraud_law/" , title:"Noozhawk: Official Work to Change Rape-by-Fraud Law"},
{link:"http://www.cnn.com/2013/01/04/justice/california-1872-rape-law/index.html" , title:"CNN:  'Arcane ' 1872 California Law cited in overturning rape conviction"},
          {link:"https://countyofsb.org/da/msm_county/Video/BOSTruancyProgramReport.wmv", title:"Boaard of Supervisors- Truancy Program Report"},
          
          {link:"http://www.noozhawk.com/article/100512_cada_kids_fight_drugs_poster_contest/" , title:"CADA Announces Winners of Kids Fight Drugs Poster Contest"},
          
          {link:"https://countyofsb.org/da/msm_county/Video/Santa Barbara County Misdemeanor Diversion Program.wmv" , title:"Santa Barbara County Misdemeanor Diversion Program"},
          {link:"https://countyofsb.org/da/msm_county/documents/OP_ED_MANDATED REPORTERS_op_ed.pdf" , title:"Mandated  Reporters of Child/Elder Abuse or Neglect"},
{link:"http://santamariatimes.com/news/local/education/schools-getting-back-teeth/article_25ffb64a-dec2-11e1-8475-0019bb2963f4.html" , title:"Fighting Truancy: Schools getting back 'teeth'"},
          {link:"https://countyofsb.org/da/msm_county/documents/SpecialOlympicssbnewspress.pdf" , title:"Special Olympics torch passes through South Coast"},
          {link:"http://youtu.be/7Yer3RHRt0c" , title:"Restorative Court-Restoring Lives Video on YouTube"},
          {link:"http://www.youtube.com/watch?v=7aeZ4RYaBnA" , title:"CalVCP Crime Victims Rights Rally Video on YouTube "},
          {link:"http://www.noozhawk.com/article/042712_woman_honored_for_dedication_to_slain_brother/" , title:"Woman Honored for Tireless Dedication to Slain Brother"},
          {link:"http://www.cscsb.org/restorative_justice/what_is_restorative_justice.html" , title:"Restorative Justice "},
          {link:"http://sbcounty.granicus.com/MediaPlayer.php?view_id=11&clip_id=1432", title:" Senate Public Safety Committee- Bill AB76"}

];

export default data;

          

// { link: "https://www.youtube.com/watch?feature=youtu.be&amp;v=EBe3QdpBJ-Y&amp;app=desktop", title: "60 Second At Ease Video"},
//   { link: "https://santamariatimes.com/news/local/crime-and-courts/santa-barbara-county-da-joyce-dudley-reappointed-chairwoman-of-post/article_d875b48a-5593-5947-bb9d-3034589b038e.html", title: "Santa Barbara County DA Joyce Dudley reappointed chairwoman of POST"},
//   { link: "https://keyt.com/news/top-stories/2020/11/15/santa-barbara-county-da-recounts-warmth-and-respect-from-kamala-harris-2/", title: "Santa Barbara County DA recounts warmth and respect from Kamala Harris"},
//   { link: "https://keyt.com/news/top-stories/2020/10/29/cada-expands-summit-for-danny-community-hike/", title: "CADA expands Summit for Danny Community Hike | NewsChannel 3-12"},
//   { link: "https://countyofsb.org/da/https://countyofsb.org/da/msm_county/documents/DiversionProgramProject1.pdf", title: "Santa Barbara County District Attorney Diversion Program"},
//   { link: "https://countyofsb.org/da/https://countyofsb.org/da/msm_county/documents/LawyersMagazineArticleJoyceDiversion.pdf", title: "Lawyers Magazine Article Joyce E. Dudley Diversion"},
//   { link: "https://www.noozhawk.com/article/Foodbank-seeking-community-volunteers-in-Santa-Maria-Lompoc?utm_source=Noozhawk&amp;utm_campaign=515522dae3-EMAIL_CAMPAIGN_2018_08_27_10_56_COPY_01&amp;utm_medium=email&amp;utm_term=0_9ec8acd2c4-515522dae3-247208801", title: "Foodbank Seeks Community Volunteers in Santa Maria, Lompoc | Good for Santa Barbara - Noozhawk.com"},
//   { link: "https://www.newsmakerswithjr.com/post/at-sentencing-of-golden-state-killer-sb-da-joyce-dudley-spoke-to-victims-of-healing-and-love", title: "Newsmakers with Jr: At Sentencing of Golden State Killer, SB DA Joyce Dudley Spoke to Victims of Healing and Love"},
//   { link: "https://keyt.com/news/santa-barbara-s-county/2020/06/29/district-attorneys-and-neighbors-speak-out-after-so-called-golden-state-killer-enters-guilty-pleas/", title: "Keyt: District Attorneys and neighbors speak out after &quot;Golden State Killer&quot; enters guilty pleas"},
//   { link: "https://newspress.com/golden-state-killer-pleads-guilty/", title: "Santa Barbara News Press: Golden State Killer pleads guilty"},
//   { link: "https://www.noozhawk.com/article/joyce_dudley_the_pain_of_looking_away", title: "Joyce Dudley: The Pain of Looking Away"},
//   { link: "images/calm.jpg", title: "CALM  Together Community Conversation"},
//   { link: "https://www.noozhawk.com/article/joyce_dudley_vickie_johnson_may_is_elder_abuse_awareness_month", title: "Joyce Dudley &amp; Vickie Johnson: May Is Elder Abuse Awareness Month | Opinions - Noozhawk.com"},
//   { link: "https://keyt.com/lifestyle/community/2020/05/13/look-for-the-helpers-das-office-pitches-in-learning-materials-donated-farmworkers-kids/", title: "Look for the Helpers: DA's office pitches in, learning materials donated to farmworkers kids"},
//   { link: "https://www.noozhawk.com/article/prosecutors_against_gun_violence_summit_20200507?utm_source=Noozhawk&amp;utm_campaign=efe42edbb5-EMAIL_CAMPAIGN_2019_03_18_03_36_COPY_01&amp;utm_medium=email&amp;utm_term=0_9ec8acd2c4-efe42edbb5-247208801", title: "Prosecutors Against Gun Violence Summit Highlights In-Home Crimes on Rise Amid Coronavirus Pandemic | Local News - Noozhawk.com"},
//   { link: "https://keyt.com/news/safety/2020/04/25/domestic-violence-during-covid-shelter-at-home-uptick-find-out-how-you-can-help/", title: "Uptick in domestic violence during COVID-19 shelter at home; find out how you can help-Keyt"},
//   { link: "https://keyt.com/news/santa-maria-north-county/2020/04/22/pets-of-slain-lompoc-woman-delivered-to-her-surviving-family-in-texas/", title: "Pets of slain Lompoc woman delivered to her surviving family in Texas- KEYT"},
//   { link: "CovidPublicSafetyConference.html", title: "Covid-19 Public Safety Press Conference"}, { link: "CovidPublicSafetyConference.html", title: "4/7/2020"},
//   { link: "CovidPublicSafetyConferenceSpanish.html", title: "Covid-19 Conferencia de Prensa - Seguridad Publica"}, { link: "CovidPublicSafetyConferenceSpanish.html", title: "4/7/2020 "},
//   { link: "https://www.santaynezvalleystar.com/domestic-violence-victims-and-their-pets-need-a-safe-haven/", title: "Domestic violence victims and their pets need a safe haven - Santa Ynez Valley Star"},
//   { link: "http://www.santamariasun.com/news/19543/coronavirusrelated-scams-pop-up-across-santa-barbara-county/", title: "Coronavirus-related scams pop up across Santa Barbara County | News | Santa Maria Sun, CA"},
//   { link: "https://www.independent.com/2020/04/07/santa-barbara-officials-call-out-domestic-abuse/", title: "Santa Barbara Officials Call Out Domestic Abuse - The Santa Barbara Independent"},
//   { link: "https://newspress.com/as-scammers-try-to-make-money-from-coronavirus-pandemic-u-s-law-enforcement-gives-tips-on-how-to-spot-and-stop-them/", title: "Santa Barbara News-Press:  As scammers try to make money from coronavirus pandemic, U.S. law enforcement gives tips on how to spot and stop them"},
//   { link: "https://www.independent.com/2020/03/23/u-s-attorneys-office-continues-its-critical-public-safety-functions-and-urges-public-to-report-suspected-covid-19-fraud/", title: "SB Independent: How to Report Suspected Scams related to COVID - 19"},
//   { link: "https://www.independent.com/2020/03/03/santa-barbara-da-reflects-on-weinstein-verdict/", title: "Santa Barbara DA Reflects on Weinstein Verdict - The Santa Barbara Independent"},
//   { link: "https://post.ca.gov/Use-of-Force-Standards#UseOfForce", title: "AB 392: Use of Force Standards General Public Video"},
//   { link: "ATEaseprogramforfirstresponders.html", title: "DA Dudley and our First Responders"},
//   { link: "https://www.noozhawk.com/article/california_highway_patrol_santa_ynez_valley_highways_20191006", title: "CHP Increases Traffic Enforcement after Crashes on Santa Ynez Valley Highways | Local News - Noozhawk.com"},
//   { link: "https://www.8newsnow.com/news/local-news/prosecutors-gather-in-las-vegas-for-gun-violence-summit-mass-shootings-in-america-took-center-stage/", title: "Prosecutors gather in Las Vegas for gun violence summit; mass shootings in America took center stage "},
//   { link: "https://www.noozhawk.com/article/santa_maria_elks_event_shows_appreciation_for_law_enforcement_officers?utm_source=Noozhawk&amp;utm_campaign=3278f9834d-EMAIL_CAMPAIGN_2019_03_18_03_36_COPY_01&amp;utm_medium=email&amp;utm_term=0_9ec8acd2c4-3278f9834d-247208801", title: "Santa Maria Elks Show Appreciation for Law Enforcement Officers | Local News - Noozhawk.com"},
//   { link: "DAvideoEnglish.html", title: "What Does the District Attorney Do?(English)"},
//   { link: "DAvideoSpanish.html", title: "What Does the District Attorey Do? (Spanish)"},
//   { link: "https://santamariatimes.com/news/local/casa-of-hope-safe-home-for-sex-trafficking-victims-to/article_f9920d01-dbab-5d8a-870a-3358d0257641.html?utm_medium=social&amp;utm_source=email&amp;utm_campaign=user-share", title: "SM Times:Casa of Hope: Safe home for sex trafficking victims to welcome its first residents"},
//   { link: "https://www.independent.com/2019/06/22/larger-rescue-mission-expands-life-changing-purpose/", title: "SB Independent: Larger Rescue Mission Expands Life-Changing Purpose"},
//   { link: "https://ksby.com/news/local-news/2019/06/13/supervisors-allocate-money-for-improvements-at-district-attorneys-offices-in-santa-maria", title: "KSBY: Supervisors allocate money for improvements at district attorney&rsquo;s offices in Santa Maria"},
//   { link: "https://www.noozhawk.com/article/conference_focus_on_toll_repeated_trauma_takes_on_first_responders_20190512", title: "Noozhawk: Conference Puts Focus on Helping First Responders Deal with Toll from Repeated Trauma"},
//   { link: "ProsecutorStories.html", title: "CDAA Prosecutor Stories: Jennifer Karapetian"},
//   { link: "https://santamariatimes.com/news/local/district-attorney-s-office-donates-books-to-santa-maria-library/article_b9a518f2-c7cb-50e8-a695-516ce64df67b.html", title: "Santa Maria Times: District Attorney's Office turns to books to prevent crime"},
//   { link: "https://www.noozhawk.com/article/book_donations_aimed_at_boosting_literacy_cutting_truancy?utm_source=Noozhawk%27s+A.M.+Report&amp;utm_campaign=4603d5de2b-EMAIL_CAMPAIGN_2018_01_11_COPY_01&amp;utm_medium=email&amp;utm_term=0_cb376aba6e-4603d5de2b-246066713", title: "Noozhawk: Santa Maria Public Library shop gets used children's books from Santa Barbara County District Attorney's Office "},
//   { link: "https://www.noozhawk.com/article/joyce_dudley_mental_health_services_early_childhood_access_20180816", title: "Noozhawk: Joyce Dudley: Access to Mental Health Services Critical During Early Childhood"},
//   { link: "https://www.noozhawk.com/article/santa_barbara_district_attorney_warns_of_blackmail_scam", title: "Noozhawk: Santa Barbara District Attorney Warns of Blackmail Scam"},
//   { link: "https://www.noozhawk.com/article/pedal_the_pacific_bike_ride_sex_trafficking_survivor_facility_santa_barbara?utm_source=Noozhawk%27s+A.M.+Report&amp;utm_campaign=516f3b64b0-EMAIL_CAMPAIGN_2018_01_11_COPY_01&amp;utm_medium=email&amp;utm_term=0_cb376aba6e-516f3b64b0-246066713", title: "Noozhawk: Pedal the Pacific Cyclists On a Roll as They Raise Awareness About Sex Trafficking"},
//   { link: "http://sacramento.cbslocal.com/video/3861754-raw-santa-barbara-da-announces-4-new-criminal-charges-against-suspected-golden-state-killer/", title: "Raw: Santa Barbara DA Announces 4 New Criminal "},
//   { link: "https://countyofsb.org/da/https://countyofsb.org/da/msm_county/Video/GoldenStateKillerPKG510.mp4", title: "Eyewitness News ABC7: Golden State charged with 4 more counts of murder"},
//   { link: "https://tedxsantabarbara.com/2017/megan-rheinschild/", title: "TEDx: The Face of Human Trafficking/Megan Rheinschild"},
//   { link: "https://www.post.ca.gov/did-you-know-video-series.aspx", title: "POST: Did You Know? Video Series"},
//   { link: "https://www.youtube.com/watch?v=WBlvzB0Gmkw&amp;t=10s", title: "Sexual Assault Awareness Video"},
//   { link: "SBCCSexualAssaultoncampus.html", title: "DA  Dudley addresses the Incoming Class at SBCC. Topic Sexual Assault on  Campus/&quot;It's on us!&quot;"},
//   { link: "https://countyofsb.org/da/https://countyofsb.org/da/msm_county/Video/pj_high.mp4", title: "Procedural Justice"},
//   { link: "humantraffickinginSB.html?ecver=2&quot; style=&quot;position:absolute;width:100%;height:100%;left:0&quot; width=&quot;641&quot; height=&quot;360&quot; frameborder=&quot;0&quot; allowfullscreen&gt;&lt;/iframe&gt;&lt;/div&gt;", title: "Human Trafficking in Santa Barbara"},                      
//   { link: "http://www.keyt.com/news/santa-barbara-s-county/elderly-homeless-woman-reunited-with-children-after-a-decade-on-the-streets/375752972", title: "Responding to the needs of our Homeless"},
//   { link: "https://countyofsb.org/da/https://countyofsb.org/da/msm_county/documents/CasaSBWomenLawyersDinner.pdf", title: "Attorney of the Year"}, 
//   { link: "SafeandJustInnovativePolicing.html", title: "Innovative policing approach to mental health, drug problems "},
//   { link: "LifeFacingBars.html", title: "Life Facing Bars: A Gang Prevention Documentary "}