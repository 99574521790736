import React, { Component, Fragment } from 'react';
import Navbar from '../../UI/Navbar';

import '../../../App.css';

class PublicRecordsRequest extends Component {
    render () {
        return (
            <Fragment>
                    <Navbar></Navbar>
                    <div className="container">
                        <div className="content-wrapper font-display text-base">
                            <div className="main-content">
                            <div className="
                                    align-self-center
                                    font-bold text-xl font-display 
                                    border-b-2 border-mustard w-max">
                                   Public Records Request
                                </div>
                                <p className="mt-4">If you would like to submit a Public Records Request or would like to request further information from our office, please email: <a href="mailto:DAPublicrecords@countyofsb.org">DAPublicrecords@countyofsb.org</a>
 </p>
                                
                            </div>
                            <div className="secondary-content">
                            <div className="
                                    align-self-center
                                    font-bold text-xl font-display 
                                    border-b-2 border-mustard w-max">
                                   Contact Information
                                </div>
                            <p>&nbsp;</p>
                            <p><a href="/contact"><strong>Santa Barbara</strong></a><br /> </p>
                            <p>            1112 Santa Barbara Street<br />
                                Santa Barbara, CA 93101<br />
                                Telephone: (805) 568-2300</p>
                            <p>&nbsp;</p>
                            <p><a href="/contact"><strong>Santa Maria</strong></a><br /></p>
                            <p>
                                312-D East Cook Street<br />
                                Santa Maria, CA 93454<br />
                                Telephone: (805) 346-7540</p>
                            </div>
                        </div>
                    </div>
            </Fragment>
        )
    }
}

export default PublicRecordsRequest;