import React, { Fragment, useState } from 'react';
import Navbar from '../../UI/Navbar';

import { ChevronRight } from 'react-bootstrap-icons';

import './DAOffice.css';
import InternApplicationForm from '../../forms/intern-application';


function Internship() {

    const [showApplication, setShowApplication] = useState(false);
    
    return (
        <Fragment>
                <Navbar></Navbar>
                <div className="container">
                    <div className="content-wrapper-no-flex font-display text-base">
                        {/* <div className="main-content"> */}
                        
                            <div className="
                                align-self-center
                                font-bold text-xl font-display 
                                border-b-2 border-mustard w-max">
                                Undergraduate Internships
                            </div>
                            
                            <p className="mt-4">The District Attorney’s Office, Bureau of Investigation partners with Santa Barbara City College and offers internships to SBCC students. If you are interested in an internship in the Bureau of Investigations, please contact the SBCC Career Center <a style={{'color': "blue"}} href='https://www.sbcc.edu/careercenter/'>https://www.sbcc.edu/careercenter/</a>    
                             </p>
                            <div className="
                                align-self-center
                                font-bold text-xl font-display 
                                border-b-2 border-mustard w-max mt-4">
                                Law Clerk &amp; Post Bar Internships
                            </div>
                            
                            <p className="mt-4">This internship is for law students and recent law school graduates who have taken the Bar and are awaiting Bar results. Post Bar positions generally run from August through mid-November. Applicants must successfully pass a background check. </p>

                            <p className="mt-4">Responsibilities include writing motions/oppositions, reviewing evidence, assisting with court preparation, observing court hearings, etc. Opportunities to appear and argue in court are also available to students and graduates who are Certified with the California State Bar. The link for Certification is included here: &nbsp;
                                <a style={{'color': 'black', 'textDecoration': 'underline'}} 
                                    href='https://www.calbar.ca.gov/Admissions/Special-Admissions/Practical-Training-of-Law-Students'>
                                        https://www.calbar.ca.gov/Admissions/Special-Admissions/Practical-Training-of-Law-Students
                                </a>
                                </p>
                            <p className="mt-4">If you are interested, please provide the information below. Application periods and deadlines are as follows:</p>
                            <p className="mt-4">Law Clerk Fall Internship – Submit application by May 31</p>
                            <p className="mt-1">Law Clerk Winter/Spring Internship – Submit application by September 30</p>
                            <p className="mt-1">Law Clerk Summer Internship – Submit application by December 31</p>
                            <p className="mt-1">Post Bar Internship – Submit application by December 31</p>

                         {!showApplication ? <button className="
                                    mt-3 py-px px-4
                                    bg-mustard 
                                    focus:outline-none hover:opacity-90"
                                    onClick={() => setShowApplication(true)}
                                    >
                                    <span className="
                                        text-sm font-normal text-white">
                                        Apply
                                        <ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-white inline" />
                                    </span>
                                </button> : null }

                                { showApplication ? <InternApplicationForm/> : null }

                            {/* <p className="mt-5 text-xl font-semibold border-b-2 border-mustard w-max">Testimonials</p> */}

                        {/* </div> */}
                        {/* <div className="secondary-content">
                            <div className="
                                align-self-center
                                font-bold text-xl font-display 
                                border-b-2 border-mustard w-max">
                                Contact Information
                            </div>
                            <p className="mt-4"><a href="mailto:jsavrnoch@co.santa-barbara.ca.us">
                                Rosemary Torres</a></p>
                                <p className="mt-2">1112 Santa Barbara St</p>
                            <p>Santa Barbara, CA 93101</p>
                            <p>Email: &nbsp;<a  style={{'color': 'black', 'textDecoration': 'underline'}}  href="mailto:rtorres@countyofsb.org">rtorres@countyofsb.org</a></p>  
                    </div> */}
                        </div>
                </div>

        </Fragment>
    )
    
}

export default Internship;