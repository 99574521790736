import React, { Component, Fragment } from 'react';
import Navbar from '../../UI/Navbar';

import '../../../App.css';

class InvPolicies extends Component {
    render () {
        return (
            <Fragment>
                    <Navbar></Navbar>
                    <div className="container">
                        <div className="content-wrapper font-display text-base">
                            <div className="main-content">
                            <div className="
                                    align-self-center
                                    font-bold text-xl font-display 
                                    border-b-2 border-mustard w-max">
                                   Bureau of Investigations - Policies
                                </div>
                            
                            <p className="mt-4">Read the Bureau of Investigations Policy Manual here: <a style={{'color': 'blue'}} href='/documents/2024 Santa Barbara County DA Office - Investigations Policy Manual.pdf'>Policy Manual</a></p>
                            </div>
                            <div className="secondary-content">
                            <div className="
                                    align-self-center
                                    font-bold text-xl font-display 
                                    border-b-2 border-mustard w-max">
                                   Contact Information
                                </div>
                                <p>&nbsp;</p>
                                
                                    <p><a href="/contact"><strong>Santa Barbara</strong></a><br />
                                    Santa Barbara, CA 93101<br />
                                    Tel: (805) 568-2300</p>
                                <p>&nbsp;</p>
                                <p><a href="/contact"><strong>Santa Maria</strong></a><br />
                                    Santa Maria, CA 93454<br />
                                    Tel: (805) 346-7540</p>
                                <p><br />
                                        <a href="/contact"><strong>Lompoc</strong></a><br />
                                    Lompoc, CA 93436<br />
                                Tel: (805) 737-7760</p>
                            </div>
                            
                        </div>
                    </div>
            </Fragment>
        )
    }
}

export default InvPolicies;