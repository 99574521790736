import React, { Component, Fragment } from 'react';
import Navbar from '../../UI/Navbar';

import './DAOffice.css';

class MissionStatement extends Component {
    render () {
        return (
            <Fragment>
                    <Navbar></Navbar>
                    <div className="container">
                        <div className="content-wrapper-no-flex font-display text-base " style={{backgroundColor: 'rgba(112,112,112,0.05)'}}>
                        <div className="
                                    align-self-center
                                    font-bold text-xl font-display 
                                   w-max">
                                   Our Mission, Values and Goals
                                </div>

                            <div className="
                                    align-self-center
                                    font-bold text-xl font-display 
                                    border-b-2 border-mustard w-max mt-4">
                                   Mission
                                </div>
                            <p className="mt-4">To pursue <strong>truth and justice</strong> by employing the highest ethical standards in vigorously prosecuting the guilty, protecting the innocent, and preventing crime.</p>​

                            <div className="
                                    align-self-center
                                    font-bold text-xl font-display 
                                    border-b-2 border-mustard w-max mt-4">
                                   Value System
                                </div>
                                <div class="flex flex-col gap-3">
                                    <p className='mt-4'><strong>Teamwork</strong> – We work together to wholeheartedly serve the community, support victims, and contribute to the fair, effective, and efficient operation of the criminal justice system.</p>
                                    <p><strong>Integrity</strong> – We conduct ourselves with professionalism, high competence, and adhere strictly to the highest ethical standards of our profession. Our focus is on doing what is right without fear or favor.</p>
                                    <p><strong>Innovative</strong> – We embrace fresh perspectives, diverse ideas, continual enhancement, and tackle challenges with creativity, swiftness, and purpose.</p>
                                    <p><strong>Tenacious</strong> – We operate with agility, imagination, and perseverance that prevents us from giving up even in the toughest of circumstances.</p>
                                    <p><strong>Human-Centered</strong>– We acknowledge and honor the humanity of all individuals, particularly those facing the most challenging circumstances within the criminal justice system.</p>
                                </div>

                            <div className="
                                align-self-center
                                font-bold text-xl font-display 
                                border-b-2 border-mustard w-max mt-6">
                                 Office Goals
                            </div>
                            <ol className='mt-4'>
                                <li className=''><strong>1. </strong>Seek justice in the most ethical, fair and efficient manner possible in everything we do.</li>
                                <li className='mt-3'><strong>2. </strong>Foster an inclusive culture that attracts and retains a diverse pool of high-caliber individuals. Prioritize professional development, encourage an openness to new and innovative problem-solving approaches, and promote mutual respect and appreciation among team members.</li>
                                <li className='mt-3'><strong>3. </strong>Create and implement modern, innovative programs that promote public safety and reflect the values of the community while reducing crime and recidivism.</li>
                                <li className='mt-3'><strong>4. </strong>Foster positive, collaborative and credible relationships with all stakeholders throughout the community to promote public safety.</li>
                                <li className='mt-3'><strong>5. </strong>Prioritize office resources in a customized manner that best addresses the specific public safety needs of each of our diverse communities.</li>
                                <li className='mt-3'><strong>6. </strong>Utilize available tools to educate the community about the criminal justice system by providing transparency in our data and by analyzing that data in a manner that is meaningful and useful to address the needs of Santa Barbara County.</li>
                            </ol>

                        </div>
                    </div>
            </Fragment>
        )
    }
}

export default MissionStatement;