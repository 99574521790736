// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

function InternApplicationForm() {
  const [state, handleSubmit] = useForm("xnqwopqg");
  
  return (
      <form className='flex flex-col text-md bg-white my-4 px-3 py-2 
            w-100 drop-shadow' 
            onSubmit={handleSubmit}
            enctype="multipart/form-data"
            >
        <div className="align-self-left font-bold text-xl font-display border-b-2 border-mustard w-max">
        Application Form
        </div>
        {
        state.succeeded ? <p className='my-4'>Thank you for submitting your application! Our team will review your application and reach out to you if we decide to move forward.</p> : <><label className='mt-6' htmlFor="name">
        Full name *
      </label>
      <input
      required
      className='mt-3 px-3 py-1'
        id="name"
        type="name" 
        name="name"
        style={{'background-color': 'rgba(112,112,112,0.10)'}}
      />
      <ValidationError 
        prefix="Name" 
        field="name"
        errors={state.errors}
      />
      <label className='mt-3' htmlFor="year">
        Year In School *
      </label>
      <input
      required
      className='mt-3 px-3 py-1'
        id="year"
        type="year" 
        name="year"
        style={{'background-color': 'rgba(112,112,112,0.10)'}}
      />
      <ValidationError 
        prefix="Year" 
        field="year"
        errors={state.errors}
      />
      <label className='mt-3' htmlFor="email">
        Email Address *
      </label>
      <input
      required
      className='mt-3 px-3 py-1'
        id="email"
        type="email" 
        name="email"
        style={{'background-color': 'rgba(112,112,112,0.10)'}}
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      
      <label className='mt-3' htmlFor="phone-number">
        Phone Number *
      </label>
      <input
      required
      className='mt-3 px-3 py-1'
        id="phone-number"
        type="phone-number" 
        name="phone-number"
        style={{'background-color': 'rgba(112,112,112,0.10)'}}
      />
      <ValidationError 
        prefix="Phone-number" 
        field="phone-number"
        errors={state.errors}
      />

      <label className='mt-3' htmlFor="resume">
        Resume *
      </label>
      <input required className='mt-2' type="file" name="resume" accept="application/msword, application/pdf"></input>
      <ValidationError 
        prefix="Resume" 
        field="resume"
        errors={state.errors}
      />

    <label className='mt-5' htmlFor="writing-sample">
        Writing Sample *
      </label>
      
      <p className='mt-2 mb-4 text-sm'>Please upload a legal brief, motion, research memorandum, journal article, or other legal writing sample that you have authored</p>
      <input  required className='mt-2' type="file" name="writing-sample" accept="application/msword, application/pdf"></input>
      <ValidationError 
        prefix="Writing-sample" 
        field="writing-sample"
        errors={state.errors}
      />

    <label className='mt-5' htmlFor="cover-letter">
        Cover Letter *
      </label>
      <p className='mt-2 mb-4 text-sm'>Please upload a cover letter explaining why you are interested in the Santa Barbara District Attorney’s Office, why you think you would be a good fit at SBDA, and what semester(s) you are available to intern</p>
      <input required className='mt-2' type="file" name="cover-letter" accept="application/msword, application/pdf"></input>
      <ValidationError 
        prefix="Cover-letter" 
        field="cover-letter"
        errors={state.errors}
      />
      
      <button className='my-5 py-px px-4 bg-mustard focus:outline-none hover:opacity-90"'
                type="submit" disabled={state.submitting}>
        <span className='text-md font-normal text-white"'>{state.submitting ? "Submitting..." : "Submit"}</span>
      </button></> }
    </form>
  );
}

export default InternApplicationForm;