import React, { Component, Fragment } from 'react';
import Navbar from '../../UI/Navbar';

import { ChevronRight } from 'react-bootstrap-icons';

class MeetYourDA extends Component {
    render () {
        return (
            <Fragment>
                    <Navbar></Navbar>
                    <div className="container">
                        <div className="content-wrapper font-display text-base">
                            <div className="main-content">
                                <div className="
                                    align-self-center
                                    font-bold text-xl font-display 
                                    border-b-2 border-mustard w-max">
                                   John T. Savrnoch
                                </div>
                                <h6 className="mt-4">Santa Barbara County District Attorney</h6>
                                <p className="mt-4">John Savrnoch was elected District Attorney of Santa Barbara County in June 2022 and assumed office on January 2nd, 2023. As a career public servant, District Attorney Savrnoch is dedicated to fulfilling the primary function of the District Attorney’s Office, which is to pursue truth and justice by employing the highest ethical standards in vigorously prosecuting the guilty, protecting the innocent, and preventing crime.</p>
                                <p className="mt-4">District Attorney Savrnoch began his legal career over 30 years ago. Prior to coming to Santa Barbara County, John was a prosecutor for the Fresno County District Attorney’s Office for 23 years where he represented abused children, and prosecuted insurance fraud and other serious financial crimes. For John’s last ten years in Fresno, he served in leadership roles including supervising the Financial Crimes and Consumer Protection Unit, supervising the Misdemeanor Prosecution Unit, and serving as an Assistant District Attorney. John also held the office of Chief Assistant District Attorney.</p>
                                <p className="mt-4">District Attorney Savrnoch joined the Santa Barbara County District Attorney’s Office in January 2015 as a Deputy District Attorney in Lompoc. In just over a year, he was promoted to Chief Deputy District Attorney. Most recently, John served as the Assistant District Attorney, where he oversaw all South County attorneys and cases. John led the office through several major innovations and initiatives, and helped design and implement positive community programs.</p>
                                <p className="mt-4">John received a Bachelor of Science and a Master of Business Administration from Marquette University in Milwaukee, Wisconsin. John attended law school at the University of Wisconsin, where he received his Juris Doctor in 1990.</p>
                                <p className="mt-4">District Attorney Savrnoch believes that public safety is a right we all share, and that every person in Santa Barbara County deserves to live in a safe neighborhood, free from violence. Throughout his career, District Attorney Savrnoch has worked to implement alternative justice programs designed to divert individuals from the criminal justice system and help offenders understand the consequences of their actions in order to reduce recidivism and protect public safety.</p>
                                <p className="mt-4">As the elected District Attorney of Santa Barbara County, District Attorney Savrnoch will bring his extensive knowledge and experience as an administrator, career prosecutor, and community leader to this most honorable office, working tirelessly to keep the people of Santa Barbara County safe and ensure that we have a fair, effective, and efficient criminal justice system.</p>
                                {/* <div className="align-self-center font-bold text-xl font-display border-b-2 border-mustard w-max mt-4">Arson Task Force</div>
                                <p className="mt-2">One of District Attorney Dudley’s first priorities was the creation of an Arson Task Force. The arson task force is available at all times to respond to, coordinate, and work with all fire agencies throughout the County in the investigation and prosecution of those responsible for unlawful fires.</p>
                                <div className="align-self-center font-bold text-xl font-display border-b-2 border-mustard w-max mt-4">Reorganization</div>
                                <p className="mt-2">To improve its efficiency and effectiveness, District Attorney Dudley reorganized the District Attorney’s Office. The Gang Unit has adopted an approach that includes education, intervention and prosecution. The Financial Crimes Unit focuses on financial and cybercrimes. The Vulnerable Victims Unit, which prosecutes domestic violence, crimes of abuse and hate crimes, has been reinforced and expanded.</p>
                                <div className="align-self-center font-bold text-xl font-display border-b-2 border-mustard w-max mt-4">Vulnerable Victims</div>
                                <p className="mt-2">Inclusive to the expansion of the Vulnerable Victims Unit, District Attorney Dudley pursued and received grant funding from the Department of Insurance and Annuity Consumer Protection. This grant funding provides for outreach and advocacy services to those who may become victims of financial abuse and exploitation in the realm of life insurance and annuities.</p>
                                <div className="align-self-center font-bold text-xl font-display border-b-2 border-mustard w-max mt-4">Addition to Canine Companion</div>
                                <p className="mt-2">District Attorney Dudley oversaw the addition of a full-time highly trained canine companion in August 2012. Cared for by a volunteer attorney, the canine companion is available to offer emotional support to victims and witnesses with psychological, physical or emotional trauma resulting from criminal conduct, at no additional cost to the County.</p>
                                <div className="align-self-center font-bold text-xl font-display border-b-2 border-mustard w-max mt-4">Volunteer Attorney Externship Progam</div>
                                <p className="mt-2">District Attorney Dudley initiated a volunteer attorney program to introduce new attorneys to the field of criminal prosecutions. Externs do research, argue motions, resolve cases on the readiness and settlement calendar, conduct preliminary hearings and when ready, try cases before juries. In addition to the Externship Program, retired Senior Deputy District Attorneys have returned as volunteers, providing invaluable service and offering expertise to new attorneys.</p>
                                <div className="align-self-center font-bold text-xl font-display border-b-2 border-mustard w-max mt-4">Truancy Program</div>
                                <p className="mt-2">District Attorney Dudley has been a strong advocate for reestablishing a Truancy Program in Santa Barbara County, which was reinstated in 2012. District Attorney Dudley knows that keeping children in school and on the path to graduation is one of the most effective crime-prevention tools available. The program will enforce the law regarding compulsory education, and will also include prevention and intervention dedicated to identifying the root causes of truancy.</p>
                                <div className="align-self-center font-bold text-xl font-display border-b-2 border-mustard w-max mt-4">Diversion Program</div>
                                <p className="mt-2">District Attorney Dudley created the Santa Barbara County Misdemeanor Diversion Program in 2011, in response to high caseload levels and jail over-crowding. Along with significant cost savings due to reduced low-level criminal cases, the program also resulted in improved efficiencies, reductions in recidivism, and more positive outcomes for those who would previously have been criminally prosecuted.</p>
                                <p className="mt-4">In addition to working closely with all public safety agencies, District Attorney Dudley is committed to working with other public agencies and private community organizations countywide to improve and coordinate efforts to prevent crime.</p>
                                <p className="mt-4">District Attorney Dudley began her legal career in the Santa Barbara County District Attorney’s Office in 1990. She has prosecuted well over one thousand cases including murder, arson, robbery, burglary, crimes involving sexual offenses, crimes against children and hate crimes. Involvement in community activities has been a priority for District Attorney Dudley during her career as a prosecutor. She has received numerous awards honoring her outstanding performance and volunteer activities from law enforcement agencies as well as community organizations</p> */}
                            </div>
                            <div className="secondary-content">
                            <div className="
                                    align-self-center mb-2
                                    font-bold text-xl font-display 
                                    border-b-2 border-mustard w-max">
                                   Education
                                </div>
                                <ul>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Juris Doctor</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Master of Business Administration</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />B.S. Business Administration</li>
                                </ul>
                                {/* <div className="
                                    align-self-center mt-4
                                    font-bold text-xl font-display 
                                    border-b-2 border-mustard w-max">
                                   Awards &amp; Distinctions
                                </div>
                                <ul>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Santa Barbara District Attorney Joyce Dudley Honored With 2022 Champion for Kids Award</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Woman of the Year (Democratic Women) 2021</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />National Co-Chair of Prosecutors Against Gun Violence (2021 - present)</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />2018 Santa Barbra's Woman of the Year (Junior League)</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Chair of the Commission on POST, State of California (2016-present)</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />	Executive Board Member Fight Crime Invest in Kids, Ca.</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />CADA : Penny Jenkins Mentor of the Year Award 2016</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Attorney of the Year, Santa Barbara Women Lawyers 2015</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Editorial Advisory Board member, California Lawyer Magazine (2013-2015)</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Commissioner-Commission on POST, State of California (2013 -present)</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Member  of California District Attorneys Association Board of Directors (2012-2014)</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Rescue Mission, Back to the Bayou, the L&eacute;ni F&eacute; Bland award (2013)</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Community Action Commission, Champion (2013)</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Santa Barbara County Schools Local Leader (2013)</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Co-chair Fighting Back Steering Committee 2012 - present</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Keynote Speaker, National California District Attorneys Association Annual Conference (2012)</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Chair Fighting Back Criminal Justice Task Force 2010 - present</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Santa Barbara County Schools Local Leader  (2010)</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Santa Barbara County Schools Community Hero  (2009 and 2010)</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Woman of Inspiration by Girls Inc. (2009)</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />The University of California at Santa Barbara,
                                            Gervitz School of Education, awarded District Attorney Dudley
                                            Alumni of the Year for Outstanding Leadership in 2005, and
                                            in 2008 the Department of Psychology chose her as their Alumni
                                    to Spotlight</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Girl Scouts Woman of Distinction (2006)</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />The Citizens Council on Crime Guerry Award
                                    for Superior Performance.  (2004)</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Soroptimist
                                        Woman of Inspiration/Human Rights (2004)</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" /> Rape Crisis
                                    Center Shining Star Award (2002)</li>
                                    <li><ChevronRight size={12} className="ml-2 mb-1 font-extrabold text-mustard inline mr-1" />Alzheimer&rsquo;s Association Celebrity Champion</li>
                                </ul> */}
                            </div>
                        </div>
                    </div>

            </Fragment>
        )
    }
}

export default MeetYourDA;