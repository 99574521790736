import React, { Component, Fragment } from "react";
import Navbar from "../../UI/Navbar";

import { ChevronRight } from "react-bootstrap-icons";

class Publications extends Component {
  render() {
    return (
      <Fragment>
        <Navbar></Navbar>
        <div className="container">
          <div
            className="content-wrapper-no-flex font-display text-base"
            style={{ backgroundColor: "rgba(112,112,112,0.05)" }}
          >
            <div
              className="
                align-self-center
                font-bold text-xl font-display 
                border-b-2 border-mustard w-max"
            >
              Downloadable Forms and Information
            </div>
            <h6 className="mt-5 mb-3 font-semibold">Criminal Division </h6>

            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="/documents/Discovery.pdf">Request for Discovery Form</a>{" "}
              (**For Use by Attorneys Only**){" "}
            </p>

            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="/documents/Citizen Complaint Form 4.5.24.pdf">
                Citizen Complaint Form
              </a>
            </p>
            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="/documents/APPLICATIONFORCONVICTIONREVIEW.pdf">
                Application for Conviction Review
              </a>
            </p>

            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="/documents/DiversionProgramProject1.pdf">
                Diversion Program Project
              </a>
            </p>

            <h6 className="mt-5 mb-3 font-semibold">
              Real Estate Fraud - Complaint Forms
            </h6>

            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1 "
              />
              <a
                className="underline text-blue-600"
                href="/documents/refraudenglish2024.pdf"
                style={{ color: "blue" }}
              >
                English
              </a>
            </p>
            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a
                className="underline text-blue-600"
                href="/documents/refraudspanish2024.pdf"
                style={{ color: "blue" }}
              >
                Spanish
              </a>
            </p>

            <h6 className="mt-5 mb-3 font-semibold">
              Workers' Compensation Fraud
            </h6>
            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="/documents/WorkersCompensationBrochure.pdf">Brochure</a>
            </p>

            <h6 className="mt-5 mb-3 font-semibold">
              Report Internet Crime here
            </h6>
            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="http://www.ic3.gov/complaint/default.aspx">
                FBI Crime Complaint Center (IC3)
              </a>
            </p>

            <h6 className="mt-5 mb-3 font-semibold">Internet Safety </h6>
            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="/documents/groomingforonlinepredators.pdf">
                Grooming for online predators
              </a>
            </p>

            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="/documents/TIPSFORPARENTS.pdf">Tips for parents</a>
            </p>

            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="/documents/FAMILYCONTRACT.pdf">Family contract</a>{" "}
            </p>

            <h6 className="mt-5 mb-3 font-semibold">Victim Witness</h6>

            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a
                href="/documents/MarsysLawEnglish.pdf?view_id=5&amp;clip_id=158&amp;publish_id=&amp;event_id="
                class="style7"
              >
                Victim's Marsy's Law: Bill of Rights Act of 2008
              </a>
            </p>

            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="/documents/EngSpanBrochure.pdf">
                Downloadable Compensation for Crime Related Expense
              </a>
            </p>

            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="/documents/RestVictimsBrochure.pdf">
                Financial Recovery: A Victim's Guide to Restitution
              </a>
            </p>

            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="http://www.sdcda.org/helping/restitution/enforcement-brochure.pdf">
                Enforcing Criminal or Juvenile Court Restitution Orders as Civil
                Judgments
              </a>
            </p>

            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="/documents/PreparingforCourt.pdf">
                How to prepare for court.
              </a>
            </p>

            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="/documents/ELDERABUSEPLEDGE.pdf">
                Stand up Against Elder Abuse Pledge Form
              </a>
            </p>

            <h6 className="mt-5 mb-3 font-semibold">Domestic Violence</h6>

            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="/documents/DVSAPPBrochure108.pdf">English Brochure</a>
            </p>
            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="/documents/DVSAPPSpanish_brochure.pdf">
                Spanish Brochure
              </a>
            </p>

            <h6 className="mt-5 mb-3 font-semibold">
              Worker's Compensation Fraud{" "}
            </h6>

            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="/documents/WCEnglishbrochure.pdf">
                Worker's Compensation Fraud: Brochure
              </a>
            </p>

            <p className="mt-5 mb-3 font-semibold">Consumer Fraud</p>

            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="/documents/ConsumerComplaintForm_Fillable.pdf">
                Consumer Complaint Form
              </a>
            </p>
            <p></p>

            <h6 className="mt-5 mb-3 font-semibold">Videos</h6>

            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="http://sbcounty.granicus.com/MediaPlayer.php?view_id=11&amp;clip_id=1443">
                The Dangers of Distracted Driving (Eric's Project)
              </a>
            </p>

            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="http://sbcounty.granicus.com/MediaPlayer.php?view_id=5&amp;clip_id=158&amp;publish_id=&amp;event_id=">
                Workers Compensation Fraud Video
              </a>
              <span class="style8">
                <br />
              </span>
              <a href="http://sbcounty.granicus.com/MediaPlayer.php?view_id=5&amp;clip_id=157&amp;publish_id=&amp;event_id="></a>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="http://sbcounty.granicus.com/MediaPlayer.php?view_id=5&amp;clip_id=190">
                Fighting Financial Fraud Video
              </a>
            </p>
            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="http://www.keyt.com/news/local/Lawmaker-Says-Rape-Victims-Should-be-Refered-to-as-Accusers-116615758.html?skipthumb=Y">
                Lawmaker Says Rape Victims Should be Referred to as
                &quot;Accusers&quot;
              </a>
            </p>
            <p>
              <ChevronRight
                size={12}
                className="ml-2 mb-1 font-extrabold text-mustard inline mr-1"
              />
              <a href="http://www.keyt.com/news/local/Lawmaker-Says-Rape-Victims-Should-be-Refered-to-as-Accusers-116615758.html?skipthumb=Y">
                KEYT Assignment Desk
              </a>
            </p>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Publications;
